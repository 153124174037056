const useCartState = () => {
  return useState<boolean>('cartSate', () => false)
}

const useCartTotalItems = () => {
  return useState<number>('cartTotalItems', () => 0)
}

export default function useCart() {
  const items = useCartTotalItems()
  const cart = useCartState()

  const show = () => {
    cart.value = true
  }

  const hide = () => {
    cart.value = false
  }

  const setTotalItems = (total: number) => {
    localStorage.setItem('cartTotalItems', `${total}`)
    items.value = total
  }

  const getTotalItems = () => {
    return items
  }

  return {
    cart,
    items,
    setTotalItems,
    getTotalItems,
    show,
    hide,
  }
}
