import { defineNuxtPlugin } from '#app'

export default defineNuxtPlugin({
  name: 'loading',
  parallel: true,
  setup(nuxtApp) {
    const { show, hide } = useLoading()
    nuxtApp.hook('page:loading:start', () => {
      show()
    })

    nuxtApp.hook('page:loading:end', () => {
      hide()
    })
  },
})
