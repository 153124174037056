interface LocalUserData {
  email: string
  firstname: string
  lastname: string
  phone?: string
  notification_count?: number
}

interface Geolocation {
  lat: number
  lng: number
}

const useLocalUserData = () => {
  return useState<LocalUserData | null>('localUserData', () => null)
}

const useLocalGeolocation = () => {
  return useState<Geolocation | null>('localGeolocation', () => null)
}

export default function useLocalUser() {
  const userData = useLocalUserData()

  const geolocation = useLocalGeolocation()

  const setGeolocation = (data: Geolocation) => {
    localStorage.setItem('localGeolocation', JSON.stringify(data))
    geolocation.value = data
  }

  const setUserData = (data: LocalUserData) => {
    localStorage.setItem('localUserData', JSON.stringify(data))

    userData.value = data
  }

  const deleteUserData = () => {
    localStorage.removeItem('localUserData')
    userData.value = null
  }

  return {
    userData: readonly(userData),
    geolocation: readonly(geolocation),
    setUserData,
    deleteUserData,
    setGeolocation,
  }
}
