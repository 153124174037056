export default defineNuxtRouteMiddleware(async (to, from) => {
  // SECTIONS
  if (to.path == '/cosmetica.html')
    return navigateTo('/es/cosmetica', { redirectCode: 301 })

  if (to.path == '/maquillaje.html')
    return navigateTo('/es/maquillaje', { redirectCode: 301 })

  if (to.path == '/perfume.html')
    return navigateTo('/es/perfumeria', { redirectCode: 301 })

  if (to.path == '/peluqueria.html')
    return navigateTo('/es/cabello-productos-peluqueria', { redirectCode: 301 })

  // MANUFACTURERS

  if (to.path == '/marcas/lancaster.html')
    return navigateTo('/es/lancaster', { redirectCode: 301 })

  if (to.path == '/marcas/clarins.html')
    return navigateTo('/es/clarins', { redirectCode: 301 })

  if (to.path == '/marcas/nivea.html')
    return navigateTo('/es/nivea', { redirectCode: 301 })

  if (to.path == '/marcas/paco-rabanne.html')
    return navigateTo('/es/rabanne', { redirectCode: 301 })

  if (to.path == '/marcas/carolina-herrera.html')
    return navigateTo('/es/carolina-herrera', { redirectCode: 301 })

  if (to.path == '/marcas/lancome.html')
    return navigateTo('/es/lancome', { redirectCode: 301 })

  if (to.path == '/marcas/jean-paul-gaultier.html')
    return navigateTo('/es/jean-paul-gaultier', { redirectCode: 301 })

  if (to.path == '/marcas/nina-ricci.html')
    return navigateTo('/es/nina-ricci', { redirectCode: 301 })

  if (to.path == '/marcas/kenzo.html')
    return navigateTo('/es/kenzo', { redirectCode: 301 })

  if (to.path == '/marcas/escada.html')
    return navigateTo('/es/escada', { redirectCode: 301 })

  if (to.path == '/marcas/biotherm.html')
    return navigateTo('/es/biotherm', { redirectCode: 301 })

  if (to.path == '/marcas/ysl.html')
    return navigateTo('/es/yves-saint-laurent', { redirectCode: 301 })

  if (to.path == '/marcas/sensai.html')
    return navigateTo('/es/sensai', { redirectCode: 301 })

  if (to.path == '/marcas/sisley.html')
    return navigateTo('/es/sisley', { redirectCode: 301 })

  if (to.path == '/marcas/elizabeth-arden.html')
    return navigateTo('/es/elizabeth-arden', { redirectCode: 301 })

  if (to.path == '/marcas/adolfo-dominguez.html')
    return navigateTo('/es/adolfo-dominguez', { redirectCode: 301 })

  if (to.path == '/marcas/olay.html')
    return navigateTo('/es/olay', { redirectCode: 301 })

  if (to.path == '/marcas/maybelline-new-york.html')
    return navigateTo('/es/maybelline', { redirectCode: 301 })

  if (to.path == '/marcas/payot.html')
    return navigateTo('/es/payot', { redirectCode: 301 })

  if (to.path == '/marcas/calvin-klein.html')
    return navigateTo('/es/calvin-klein', { redirectCode: 301 })

  if (to.path == '/marcas/nyx-professional-makeup.html')
    return navigateTo('/es/nyx', { redirectCode: 301 })

  if (to.path == '/marcas/bella-aurora.html')
    return navigateTo('/es/bella-aurora', { redirectCode: 301 })

  if (to.path == '/marcas/loewe.html')
    return navigateTo('/es/loewe', { redirectCode: 301 })

  if (to.path == '/marcas/thierry-mugler.html')
    return navigateTo('/es/mugler', { redirectCode: 301 })

  if (to.path == '/marcas/natura-bisse.html')
    return navigateTo('/es/natura-bissé', { redirectCode: 301 })

  if (to.path == '/marcas/garnier.html')
    return navigateTo('/es/garnier', { redirectCode: 301 })

  if (to.path == '/marcas/l-oreal.html')
    return navigateTo('/es/loreal-paris', { redirectCode: 301 })

  if (to.path == '/marcas/pantene.html')
    return navigateTo('/es/pantene', { redirectCode: 301 })

  if (to.path == '/marcas/narciso-rodriguez.html')
    return navigateTo('/es/narciso-rodriguez', { redirectCode: 301 })

  if (to.path == '/marcas/issey-miyake.html')
    return navigateTo('/es/issey-miyake', { redirectCode: 301 })

  if (to.path == '/marcas/sanex.html')
    return navigateTo('/es/sanex', { redirectCode: 301 })

  if (to.path == '/marcas/all-natural.html')
    return navigateTo('/es/all-natural', { redirectCode: 301 })

  if (to.path == '/marcas/schwarzkopf.html')
    return navigateTo('/es/schwarzkopf', { redirectCode: 301 })

  if (to.path == '/marcas/dr-hauschka.html')
    return navigateTo('/es/dr-hauschka', { redirectCode: 301 })

  if (to.path == '/marcas/lacer.html')
    return navigateTo('/es/lacer', { redirectCode: 301 })

  if (to.path == '/marcas/tous.html')
    return navigateTo('/es/tous', { redirectCode: 301 })

  if (to.path == '/marcas/hermes.html')
    return navigateTo('/es/hermes', { redirectCode: 301 })

  if (to.path == '/marcas/lactovit.html')
    return navigateTo('/es/lactovit', { redirectCode: 301 })

  if (to.path == '/marcas/hugo-boss.html')
    return navigateTo('/es/hugo-boss', { redirectCode: 301 })

  if (to.path == '/marcas/dolce-gabbana.html')
    return navigateTo('/es/dolce-and-gabbana', { redirectCode: 301 })

  if (to.path == '/marcas/valentino.html')
    return navigateTo('/es/valentino', { redirectCode: 301 })

  if (to.path == '/marcas/ecran.html')
    return navigateTo('/es/ecran', { redirectCode: 301 })

  if (to.path == '/marcas/bvlgari.html')
    return navigateTo('/es/bvlgari', { redirectCode: 301 })

  if (to.path == '/marcas/ralph-lauren.html')
    return navigateTo('/es/ralph-lauren', { redirectCode: 301 })

  if (to.path == '/marcas/moussel.html')
    return navigateTo('/es/moussel', { redirectCode: 301 })

  if (to.path == '/marcas/dkny.html')
    return navigateTo('/es/dkny', { redirectCode: 301 })

  // CATEGORIES

  if (to.path == '/perfume/perfumes-para-hombre.html')
    return navigateTo('/es/perfumes-hombre', { redirectCode: 301 })

  if (to.path == '/perfume/perfumes-para-mujer.html')
    return navigateTo('/es/perfumes-mujer', { redirectCode: 301 })

  if (to.path == '/solares.html')
    return navigateTo('/es/fotoprotectores-solares', { redirectCode: 301 })

  if (to.path == '/maquillaje/ojos.html')
    return navigateTo('/es/maquillaje-ojos', { redirectCode: 301 })

  if (to.path == '/maquillaje/labios.html')
    return navigateTo('/es/maquillaje-labios', { redirectCode: 301 })
})
