import { defineNuxtPlugin } from '#app'

export default defineNuxtPlugin({
  name: 'doofinder',
  parallel: true,
  setup(nuxtApp) {
    nuxtApp.hook('app:mounted', () => {
      const addDoofinderScript = () => {
        const dfLayerOptions = {
          installationId: 'cdb257c4-a3e6-48ae-ab88-aa30574335b9',
          zone: 'eu1',
        }

        const script = document.createElement('script')
        script.innerHTML = `
          (function (l, a, y, e, r, s) {
            r = l.createElement(a); r.onload = e; r.async = 1; r.src = y;
            s = l.getElementsByTagName(a)[0]; s.parentNode.insertBefore(r, s);
          })(document, 'script', 'https://cdn.doofinder.com/livelayer/1/js/loader.min.js', function () {
            doofinderLoader.load(${JSON.stringify(dfLayerOptions)});
          });
        `

        document.body.appendChild(script)
      }

      if ('requestIdleCallback' in window) {
        window.requestIdleCallback(addDoofinderScript)
      } else {
        // Fallback para navegadores que no soportan requestIdleCallback
        setTimeout(addDoofinderScript, 0)
      }
    })
  },
})
