const useLoadingState = () => {
  return useState<Boolean>('nuxtLoader', () => false)
}

const useBypassLoadingState = () => {
  return useState<Boolean>('bypassLoader', () => false)
}

export default function useLoading() {
  const nuxtLoader = useLoadingState()
  const bypassLoader = useBypassLoadingState()

  const show = () => {
    if (bypassLoader.value) return
    nuxtLoader.value = true
  }

  const hide = () => {
    nuxtLoader.value = false
    bypassLoader.value = false
  }

  const bypassLoading = () => {
    bypassLoader.value = true
  }

  return {
    nuxtLoader,
    bypassLoading,
    show,
    hide,
  }
}
