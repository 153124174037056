import { watch } from 'vue'

export default defineNuxtPlugin(() => {
  if (process.server) return

  const consent = useState('consent')

  watch(
    consent,
    (newConsent) => {
      const config = useRuntimeConfig()
      if (newConsent.marketing) {
        useScriptMetaPixel({
          id: `${config.public.META_PIXEL_ID}`,
        })
      }
    },
    { immediate: true }
  )
})
