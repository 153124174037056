import { defineNuxtPlugin } from '#app/nuxt'
import { LazyBannerSlider, LazyBannerSliderItem, LazyBeautyStoriesSlider, LazyBeautyStoriesSliderItem, LazyBrandAction, LazyBrandCategorySlider, LazyBrandCategorySliderItem, LazyBrandGrid, LazyBrandIconsSlider, LazyBrandIconsSliderItem, LazyBrandSlider, LazyBrandSliderItem, LazyBrandedProductSlider, LazyBrandedProductSliderImage, LazyBrandedProductSliderProducts, LazyCTAGridKnowUs, LazyCTAGridKnowUsItem, LazyCallToAction, LazyCategoryRow, LazyCategoryRowItem, LazyCookieTable, LazyCustomHTMLBlock, LazyHeaderBlok, LazyHeroKnowUs, LazyHeroSlider, LazyHeroSliderItem, LazyImagesGridKnowUs, LazyInfoBar, LazyInfoBarText, LazyInfoSectionKnowUs, LazyInfoSectionKnowUsItem, LazyLegalPageHeader, LazyNewsletterAction, LazyPage, LazyProductGrid, LazyProductsSlider, LazyPromoBar, LazyPromoBarCode, LazyPromoBarCountdown, LazyPromoBarDiscount, LazyPromoBarDiscountMobile, LazySocialSlider, LazySocialSliderItem, LazyStoresKnowUs, LazyTextKnowUs, LazyTopBar, LazyTopBarLink, LazyTopBarText } from '#components'
const lazyGlobalComponents = [
  ["BannerSlider", LazyBannerSlider],
["BannerSliderItem", LazyBannerSliderItem],
["BeautyStoriesSlider", LazyBeautyStoriesSlider],
["BeautyStoriesSliderItem", LazyBeautyStoriesSliderItem],
["BrandAction", LazyBrandAction],
["BrandCategorySlider", LazyBrandCategorySlider],
["BrandCategorySliderItem", LazyBrandCategorySliderItem],
["BrandGrid", LazyBrandGrid],
["BrandIconsSlider", LazyBrandIconsSlider],
["BrandIconsSliderItem", LazyBrandIconsSliderItem],
["BrandSlider", LazyBrandSlider],
["BrandSliderItem", LazyBrandSliderItem],
["BrandedProductSlider", LazyBrandedProductSlider],
["BrandedProductSliderImage", LazyBrandedProductSliderImage],
["BrandedProductSliderProducts", LazyBrandedProductSliderProducts],
["CTAGridKnowUs", LazyCTAGridKnowUs],
["CTAGridKnowUsItem", LazyCTAGridKnowUsItem],
["CallToAction", LazyCallToAction],
["CategoryRow", LazyCategoryRow],
["CategoryRowItem", LazyCategoryRowItem],
["CookieTable", LazyCookieTable],
["CustomHTMLBlock", LazyCustomHTMLBlock],
["HeaderBlok", LazyHeaderBlok],
["HeroKnowUs", LazyHeroKnowUs],
["HeroSlider", LazyHeroSlider],
["HeroSliderItem", LazyHeroSliderItem],
["ImagesGridKnowUs", LazyImagesGridKnowUs],
["InfoBar", LazyInfoBar],
["InfoBarText", LazyInfoBarText],
["InfoSectionKnowUs", LazyInfoSectionKnowUs],
["InfoSectionKnowUsItem", LazyInfoSectionKnowUsItem],
["LegalPageHeader", LazyLegalPageHeader],
["NewsletterAction", LazyNewsletterAction],
["Page", LazyPage],
["ProductGrid", LazyProductGrid],
["ProductsSlider", LazyProductsSlider],
["PromoBar", LazyPromoBar],
["PromoBarCode", LazyPromoBarCode],
["PromoBarCountdown", LazyPromoBarCountdown],
["PromoBarDiscount", LazyPromoBarDiscount],
["PromoBarDiscountMobile", LazyPromoBarDiscountMobile],
["SocialSlider", LazySocialSlider],
["SocialSliderItem", LazySocialSliderItem],
["StoresKnowUs", LazyStoresKnowUs],
["TextKnowUs", LazyTextKnowUs],
["TopBar", LazyTopBar],
["TopBarLink", LazyTopBarLink],
["TopBarText", LazyTopBarText],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
