import validate from "/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/node_modules/nuxt/dist/pages/runtime/validate.js";
import _0_45redirect_45global from "/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/middleware/0.redirect.global.ts";
import _01_45clean_45global from "/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/middleware/01.clean.global.ts";
import _02_45legacy_45global from "/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/middleware/02.legacy.global.ts";
import manifest_45route_45rule from "/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _0_45redirect_45global,
  _01_45clean_45global,
  _02_45legacy_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/middleware/auth.ts"),
  route: () => import("/Users/raulretuerto/dev/cloudfare-workers/nuxt-frontend/.sanremo/middleware/route.ts")
}