// user is set outside of the useAuthUser function

import useLocalUser from './useLocalUser'

// so that it will act as global state and always refer to a single user
export const useAuthUserState = () => {
  return useState<object>('authUserState', () => {
    return {}
  })
}

export const useIsRegisterState = () => {
  return useState<boolean>('isRegisterState', () => false)
}

async function init(): Promise<void> {
  const { initializeApp, getApps } = await import('firebase/app')
  if (getApps().length === 0) {
    const config = useRuntimeConfig()
    const firebaseConfig = {
      apiKey: config.public.FIREBASE_API_KEY,
      authDomain: config.public.FIREBASE_AUTH_DOMAIN,
      projectId: config.public.FIREBASE_PROJECT_ID,
      appId: config.public.FIREBASE_APP_ID,
    }
    initializeApp(firebaseConfig)
  }
}

export default function useAuthUser() {
  const authUser = useAuthUserState()
  const isRegister = useIsRegisterState()
  /**
   * Register
   */
  const register = async ({
    email,
    password,
    meta,
  }: {
    email: string
    password: string
    meta: object
  }) => {
    isRegister.value = true
    await init()
    const { getAuth, createUserWithEmailAndPassword } = await import(
      'firebase/auth'
    )
    const auth = getAuth()

    // FIREBASE REGISTER USER
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    )

    const user = userCredential.user
    if (user) {
      const token = await user.getIdToken()
      const { data, error } = await useAsyncData(
        'afterSignUp',
        () =>
          $fetch('/api/public/auth/aftersignup', {
            method: 'POST',
            body: {
              authToken: token,
              meta: meta,
            },
          }),
        {}
      )

      if (error.value) {
        throw error.value
      }
    }

    // Signed in
    authUser.value = user
    setUser(meta)
  }

  /**
   * Login with email and password
   */
  const login = async ({
    email,
    password,
    cookieConsentData,
  }: {
    email: string
    password: string
    cookieConsentData: object
  }) => {
    isRegister.value = false
    await init()
    const { getAuth, signInWithEmailAndPassword } = await import(
      'firebase/auth'
    )
    const auth = getAuth()
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    )

    const user = userCredential.user

    if (user) {
      authUser.value = user
      const token = await user.getIdToken()
      const { data } = await useAsyncData(
        'token',
        () =>
          $fetch('/api/public/auth/aftersignin', {
            method: 'POST',
            body: { authToken: token, cookie_consent: cookieConsentData },
          }),
        {
          server: false,
        }
      )
      setUser(data.value.data)
      const { sendLogin } = useConnectif()
      sendLogin()
    }
  }

  /**
   * Login with email and password
   */
  const autologin = async (token: string) => {
    const { data } = await useAsyncData(
      'token',
      () =>
        $fetch('/api/public/auth/aftersignin', {
          method: 'POST',
          body: { authToken: token },
        }),
      {
        server: false,
      }
    )
    setUser(data.value.data)
    const { sendLogin } = useConnectif()
    sendLogin()
  }

  const verifyResetCode = async (code: string) => {
    const { getAuth, verifyPasswordResetCode } = await import('firebase/auth')
    const auth = getAuth()
    const email = await verifyPasswordResetCode(auth, code)
    return email
  }

  const confirmNewPassword = async (oobCode: string, newPassword: string) => {
    const { getAuth, confirmPasswordReset } = await import('firebase/auth')
    const auth = getAuth()
    await confirmPasswordReset(auth, oobCode, newPassword)
  }

  /**
   * Login with google, github, etc
   */
  const loginWithSocialProvider = async (
    providerName: string,
    cookieConsentData: object,
    locale: string
  ) => {
    const { getAuth, GoogleAuthProvider, signInWithPopup } = await import(
      'firebase/auth'
    )
    let provider
    let providerInstance
    switch (providerName) {
      case 'google':
        provider = new GoogleAuthProvider()
        providerInstance = GoogleAuthProvider
        break

      default:
        break
    }

    const auth = getAuth()
    auth.languageCode = locale

    const result = await signInWithPopup(auth, provider)
    const credential = providerInstance!.credentialFromResult(result)
    if (credential === null) return null
    const user = result.user
    const authToken = await user.getIdToken()

    const { data } = await useAsyncData('loginWithGoogle', () =>
      $fetch('/api/public/auth/aftersignin', {
        method: 'POST',
        body: {
          authToken: authToken,
          cookie_consent: cookieConsentData,
        },
      })
    )

    isRegister.value = data.value.data?.isRegistering
    setUser(data.value.data)
    const { sendLogin } = useConnectif()
    sendLogin()
  }

  /**
   * Logout
   */
  const logout = async () => {
    const { getAuth, signOut } = await import('firebase/auth')
    const auth = getAuth()
    await signOut(auth)
    authUser.value = {} // Set to an empty object instead of null
    const { deleteUserData } = useLocalUser()
    deleteUserData()
    await useAsyncData('deleteCookie', () =>
      $fetch('/api/public/auth/aftersignout')
    )
  }

  /**
   * Check if the user is logged in or not
   */
  const isLoggedIn = async (): Promise<boolean> => {
    if (Object.keys(authUser.value).length > 0) return true
    await init()
    const { getAuth } = await import('firebase/auth')
    const auth = getAuth()
    const user = await new Promise<any>((resolve, reject) => {
      const unsubscribe = auth.onAuthStateChanged(async (user) => {
        unsubscribe()
        if (user) {
          console.debug('onAuthStateChanged on middleware: User logged')
          resolve(user)
        } else {
          console.debug('onAuthStateChanged on middleware: User not logged')
          resolve(null)
        }
      }, reject)
    })
    if (user) {
      authUser.value = user
    }

    return user != null
  }

  /**
   * Update user email, password, or meta data
   */
  const update = async (data) => {}

  /**
   * Send user an email to reset their password
   * (ie. support "Forgot Password?")
   */
  const sendPasswordResetEmail = async (email) => {}

  /**
   * Set user with name, lastname and email
   */
  const setUser = (data: object) => {
    authUser.value.user_data = {
      firstname: data.firstname,
      lastname: data.lastname,
      email: data.email,
      phone: data?.phone,
      notification_count: data?.notification_count,
    }

    const { setUserData } = useLocalUser()
    setUserData({
      email: data.email,
      firstname: data.firstname,
      lastname: data.lastname,
      phone: data?.phone,
      notification_count: data?.notification_count,
    })
  }

  const getUser = () => {
    return authUser.value?.user_data
  }

  const getUserToken = async () => {
    const { getAuth } = await import('firebase/auth')
    return getAuth().currentUser?.getIdToken()
  }

  return {
    isRegister,
    authUser,
    login,
    autologin,
    loginWithSocialProvider,
    isLoggedIn,
    logout,
    register,
    verifyResetCode,
    confirmNewPassword,
    update,
    sendPasswordResetEmail,
    setUser,
    getUser,
    getUserToken,
  }
}
