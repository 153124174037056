import { defineNuxtPlugin } from '#app'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.$router.afterEach((to, from) => {
    if (process.client) {
      // Verificar si la URL tiene un hash

      if (!to.query.variant) {
        // Hacer scroll al top de la página
        window.scrollTo(0, 0)
      }
    }
  })
})
